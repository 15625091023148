import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1><a href="http://11o.ooo">11o.ooo</a></h1>
    </div>
  );
}

export default App;
